<template>
  <div id="ContasReceber" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
    <v-overlay :value="loading_relatorio">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- Título da página -->
      <div class="justify-space-between d-flex">
        <v-toolbar-title class="text-white title-page mt-2 ml-4 ">Contas a Receber</v-toolbar-title>

        <v-btn v-if="acao == 'SELECIONAR'" @click="fechar()" icon dark color="#F2F6F7" class="mr-2 mt-2 mb-n2">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </div>

        <!-- Valores de consulta padrao para quando esta na selecao (EX: Pagamento em Lote) -->
        <div v-if="acao == 'SELECIONAR'" class="d-flex ml-4 mt-n3 mb-n4">
          <v-checkbox
              v-model="filtro_mesmo_valor"
              outlined
              dark
              style="width:100px!important"
              color="white"
              label="Filtrar mesmo valor da cobrança"
              class="checkbox-label mr-11"
              @click="filtroMesmoValor()"
              >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-white body-2">Mesmo valor</span>
                </template>
                Filtra as cobranças com mesmo valor
              </v-tooltip>
            </template>              
          </v-checkbox>

          <v-checkbox
              v-model="filtro_mesmo_valor_cnab"
              outlined
              dark
              style="width:200px!important"
              color="white"
              label="Filtrar cobranças do CNAB Retorno"
              class="checkbox-label mr-11"
              @click="filtroMesmoValorCNAB()"
              >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-white body-2">Mesmo valor do CNAB de Retorno</span>
                </template>
                Filtra todas cobranças contidas no arquivo CNAB com o valor total de liquidação: {{ formatPrice(dialog_lancamentos?.valor) }} 
              </v-tooltip>
            </template>              
          </v-checkbox>

          <v-checkbox
              v-model="filtro_mesma_data_pagto"
              outlined
              dark
              style="width:40px!important"
              color="white"
              label="Mesma data"
              class="checkbox-label ml-11"
              @click="filtroMesmaDataPagto()"
              >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-white text-capitalize body-2">Mesma data de Pagamento</span>
                </template>
                Filtra as cobranças com mesma de pagamento do extrato
              </v-tooltip>
            </template>              
          </v-checkbox>
        </div>
      
        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-6 mr-4 ml-4 pa-0"
          dark
          elevation="0"
        >
          <div class="d-flex " tabindex="-1">

          <!-- Text Field com Consulta ----------------------------------------->
          <v-container
            class="pa-0 ma-0 mb-7 d-flex "
            style="margin-top: -4px;">
              <v-menu
                id="vMenu"
                ref="vMenu"
                v-model="menu"
                bottom
                right
                fluid
                offset-y
                origin="top"
                class="pa-0 d-flex flex-wrap"
                elevation=0
                :close-on-content-click="false"
                @input="abriuConsulta">
                <template v-slot:activator="{ on }">

                  <div
                    ref="vMenuDiv"
                    class="false-filter d-flex align-center"
                    align="center"
                    v-on="on"
                  >
                    <v-icon class="false-icon ml-3 mr-2" color="#FFF">mdi-magnify</v-icon>

                    <div class="false-text flex-wrap" >

                      <!-- Quando é conciliacao, Este chip traz apenas cobrancas vinculas a conta que esta sendo conciliada -->
                      <v-chip
                        v-if="store_ContasReceber.filtro.pessoa_conta_nome"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Conta: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.pessoa_conta_nome}}</strong>
                      </v-chip>

                      <v-chip
                        v-if="store_ContasReceber.filtro.num_proposta"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Nº Proposta: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.num_proposta}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.cliente_nome"
                        style="max-width: 250px;" 
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Cliente&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.cliente_nome}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.empreendimento"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1 pa-2"
                      >
                        <!-- <span   class="mt-n5 ml-2"  v-bind:style="{ 'color':COR_PRINCIPAL }">Empreendimento:</span><br>
                        <strong class="mt-4 ml-n16" v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.empreendimento.empreend_nome}}</strong> -->
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Empr: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.empreendimento.empreend_nome}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.quadra"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Quadra&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.quadra}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.lote"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Lote&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.lote}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_dt_inicial"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Venc Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_dt_inicial) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_dt_final"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Venc Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_dt_final) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.proposta_dt_inicial"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Proposta Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.proposta_dt_inicial) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.proposta_dt_final"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Proposta Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.proposta_dt_final) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.corretor"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        {{store_ContasReceber.filtro.corretor.corretor_nome}}
                      </v-chip>
                      <v-chip
                        v-if="(store_ContasReceber.filtro.situacao == 'Rescindido' && !filtro_local.rescindidos) || 
                              (filtro_local.rescindidos && (store_ContasReceber.filtro.situacao=='Pago' || store_ContasReceber.filtro.situacao=='Aberto'))"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                       >
                        <strong  
                            v-if="store_ContasReceber.filtro.situacao=='Aberto'"
                            v-bind:style="{ 'color':COR_PRINCIPAL }">
                            Abertos
                          </strong>
                          <strong  
                            v-if="store_ContasReceber.filtro.situacao=='Pago'"
                            v-bind:style="{ 'color':COR_PRINCIPAL }">
                            Pagos
                          </strong>
                          <strong  
                            v-if="store_ContasReceber.filtro.situacao=='Rescindido'"
                            v-bind:style="{ 'color':COR_PRINCIPAL }">
                            Rescindidos
                          </strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.tipo_parcela"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Tipo Parcela: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.tipo_parcela}}</strong>
                      </v-chip>
                      
                      <v-chip
                        v-if="store_ContasReceber.filtro.tipo_impressao"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.tipo_impressao}}</strong>
                      </v-chip>

                      <!-- <v-chip
                        v-if="store_ContasReceber.filtro.proprietario"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Proprietario:</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.proprietario}}</strong>
                      </v-chip>                       -->
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_dt_pagto_inicial"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pagto Inicial >= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_dt_pagto_inicial) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_dt_pagto_final"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pagto Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_dt_pagto_final) }}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_valor_inicial"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Vl Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.parc_valor_inicial}}</strong>
                      </v-chip>
                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_valor_final"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Vl Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.parc_valor_final}}</strong>
                      </v-chip>

                      <v-chip v-if="store_ContasReceber.filtro.parc_valor_pagto_inicial" pill v-on="on" small class="ml-1 mr-1">
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pag. Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_valor_pagto_inicial) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasReceber.filtro.parc_valor_pagto_final" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pag. Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.parc_valor_pagto_final) }}</strong>
                      </v-chip>

                      <v-chip
                        v-if="store_ContasReceber.filtro.parc_valor_cnab"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Valor CNAB= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.parc_valor_cnab}}</strong>
                      </v-chip>

                      <v-chip
                        v-if="store_ContasReceber.filtro.impressao_inicial_dt"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Impressão Inicial&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.impressao_inicial_dt) }}</strong>
                      </v-chip>    
                      <v-chip
                        v-if="store_ContasReceber.filtro.impressao_final_dt"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Impressão Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.impressao_final_dt) }}</strong>
                      </v-chip>  
                      <v-chip
                        v-if="store_ContasReceber.filtro.numero_contrato"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Nº Contrato&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.numero_contrato}}</strong>
                      </v-chip>   
                      <v-chip
                        v-if="store_ContasReceber.filtro.data_contrato"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Data Contrato&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasReceber.filtro.data_contrato) }}</strong>
                      </v-chip>  
                      <v-chip
                        v-if="store_ContasReceber.filtro.num_cobranca"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Nº Documento&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasReceber.filtro.num_cobranca}}</strong>
                      </v-chip>                                                                                                                         
                      <v-chip
                        v-if="store_ContasReceber.filtro.opcoes_bloqueados"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <strong  
                          v-if="store_ContasReceber.filtro.opcoes_bloqueados.text=='Não'"
                          v-bind:style="{ 'color':COR_PRINCIPAL }">
                          Não Bloqueados
                        </strong>
                        <strong  
                          v-if="store_ContasReceber.filtro.opcoes_bloqueados.text=='Sim'"
                          v-bind:style="{ 'color':COR_PRINCIPAL }">
                          Bloqueados
                        </strong>
                      </v-chip> 
                      <v-chip
                        v-if="store_ContasReceber.filtro.opcoes_parcela"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Parcela&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }"> {{store_ContasReceber.filtro.opcoes_parcela.text}}</strong>
                      </v-chip>

                      <v-chip
                        v-if="store_ContasReceber.filtro.opcoes_reajustados"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <strong  
                          v-if="store_ContasReceber.filtro.opcoes_reajustados.text=='Não'"
                          v-bind:style="{ 'color':COR_PRINCIPAL }">
                          Não Reajustados
                        </strong>
                        <strong  
                          v-if="store_ContasReceber.filtro.opcoes_reajustados.text=='Sim'"
                          v-bind:style="{ 'color':COR_PRINCIPAL }">
                          Reajustados
                        </strong>
                      </v-chip> 
                      <v-chip
                       v-if="filtro_local.rescindidos"
                       pill
                       v-on="on"
                       small
                       class="ml-1 mr-1"
                        >
                        <strong  
                          v-bind:style="{ 'color':COR_PRINCIPAL }">
                          Rescindidos
                        </strong>
                      </v-chip>
                      
                                                                                                                                              
                    </div>

                    <v-icon class="false-icon mr-4 ml-2" color="#FFF">mdi-filter-variant-plus</v-icon>

                  </div>
                </template>

                <v-container v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }"
                             >
<v-row no-gutters class="d-flex">
  <span class="font-weight body-1" v-bind:style="{ 'color':COR_SUBTITULO }">Proposta / Contrato</span>
</v-row>
<v-row no-gutters class="linhas">

                      <v-col md="2">
                        <!-- Num Proposta ----------------------- -->
                        <v-text-field
                          id="txtProposta"
                          ref="txtProposta"
                          dense
                          v-model="filtro_local.num_proposta"
                          cache-items
                          flat
                          label="Nº Proposta"
                          placeholder="Nº Proposta"
                          outlined
                          filled
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                          
                          class="mr-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-select
                          :items="empreendimentos"
                          v-model="filtro_local.empreendimento"
                          item-text="empreend_nome"
                          item-value="cod_empreendimento"
                          label="Empreendimento"
                          placeholder="Empreendimento"
                          outlined
                          filled
                          background-color="#FFF"
                          dense
                          required
                          flat
                          clearable
                          tabindex="0"
                          return-object
                          class="mr-2"
                        ></v-select>
                      </v-col>
                      <v-col md="2">
                        <!-- Quadra ----------------------- -->
                        <v-text-field
                          id="txtQuadra"
                          ref="txtQuadra"
                          dense
                          v-model="filtro_local.quadra"
                          cache-items
                          flat
                          label="Quadra"
                          placeholder="Quadra"
                          outlined
                          filled
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                          class="mr-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="2">
                        <!-- Lote ----------------------- -->
                        <v-text-field
                          id="txtLote"
                          ref="txtLote"
                          dense
                          v-model="filtro_local.lote"
                          cache-items
                          flat
                          label="Lote"
                          placeholder="Lote"
                          outlined
                          filled
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters class="linhas">
                      <v-col md="3">
                        <!-- Proposta Inicial ----------------------- -->
                        <DatePicker
                          id="txtPropInicial"
                          ref="txtPropInicial"
                          v-model="filtro_local.proposta_dt_inicial"
                          label="Proposta Inicial"
                          placeholder="dia/mês/ano"
                          class="mr-2"
                          outlined
                          backgroundColor="#FFF"
                          :style="data_picker"
                          dense>
                        </DatePicker>
                      </v-col>
                      <v-col md="3">
                        <!-- Proposta Final ----------------------- -->
                        <DatePicker
                          id="txtPropFinal"
                          ref="txtPropFinal"
                          v-model="filtro_local.proposta_dt_final"
                          label="Proposta Final"
                          placeholder="dia/mês/ano"
                          class="mr-2"
                          outlined
                          backgroundColor="#FFF"
                          :style="data_picker"
                          dense>
                        </DatePicker>
                      </v-col>
                      <v-col md="3">
                        <!-- Contrato ----------------------- -->
                        <v-text-field
                          id="txtContrato"
                          ref="txtContrato"
                          dense
                          v-model="filtro_local.numero_contrato"
                          cache-items
                          flat
                          label="Nº Contrato"
                          placeholder="Nº Contrato"
                          outlined
                          filled
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                           
                          class="mr-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="3">
                        <!-- Data Contrato ----------------------- -->
                        <DatePicker
                          v-model="filtro_local.data_contrato"
                          label="Data Contrato"
                          placeholder="dia/mês/ano"
                          class=""
                          outlined
                          backgroundColor="#FFF"
                          :style="data_picker"
                          dense>
                        </DatePicker>
                      </v-col>        
</v-row>
<v-row no-gutters class="linhas">
                      <v-col md="5">
                        <!-- Cliente ------------------------ -->
                        <v-text-field
                          id="txtCliente"
                          ref="txtCliente"
                          dense
                          v-model="filtro_local.cliente_nome"
                          cache-items
                          flat
                          label="Cliente"
                          placeholder="Cliente (nome ou cpf)..."
                          outlined
                          filled
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                          class="mr-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="2">
                      <!-- Reajustados ----------------------- -->
                      <v-select
                        :items="opcoes_reajustados"
                        v-model="filtro_local.opcoes_reajustados"
                        item-text="text"
                        item-value="value"
                        label="Reajustados"
                        placeholder="Reajustados"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class="mr-2"
                      ></v-select>
                    </v-col>
                    <v-col md="2">
                      <!-- Bloqueados ----------------------- -->
                      <v-select
                        :items="opcoes_bloqueados"
                        v-model="filtro_local.opcoes_bloqueados"
                        item-text="text"
                        item-value="value"
                        label="Bloqueados"
                        placeholder="Bloqueados"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class="mr-2"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <!-- Rescindidos ----------------------- -->
                      <v-checkbox
                        v-model="filtro_local.rescindidos"
                        label="Mostrar Rescindidos"
                        item-value = "true"
                        placeholder="Mostrar Rescindidos"
                        outlined
                        filled
                        background-color="transparent"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class="mr-2"
                      ></v-checkbox>
                    </v-col>
</v-row>
<v-divider></v-divider>
<v-row no-gutters class="mt-2 mb-2 d-flex">
  <span class="me-5 font-weight body-1" v-bind:style="{ 'color':COR_SUBTITULO }">Cobrança / Documento</span>
                    <v-btn class="mr-3 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(-1, 'isActiveVencidos')" v-model="isActiveVencidos" active elevation="0" outlined tabindex="-1" small>
                      <span>Em atraso</span>
                    </v-btn>
                    
                    <v-btn class="mr-3 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(0, 'isActiveHoje')" v-model="isActiveHoje" active elevation="0" outlined tabindex="-1" small>
                      <span>Hoje</span>
                    </v-btn>

                    <v-btn class="mr-3 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(7, 'isActiveSemana')" v-model="isActiveSemana" active elevation="0" outlined tabindex="-1" small>
                      <span>7 dias</span>
                    </v-btn>

                    <v-btn class="mr-3 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(30, 'isActiveMes')" v-model="isActiveMes" active elevation="0" outlined tabindex="-1" small>
                      <span>30 dias</span>
                    </v-btn>
</v-row>

<v-row no-gutters class="linhas">
                    <v-col md="3">
                      <!-- Cobrança ----------------------- -->
                      <v-text-field
                        id="txtCobranca"
                        ref="txtCobranca"
                        dense
                        v-model="filtro_local.num_cobranca"
                        cache-items
                        flat
                        label="Nº Documento"
                        placeholder="Nº Documento"
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"                         
                        class="mr-2"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col md="3">
                      <!-- Situação ----------------------- -->
                      <v-select
                        :items="opcoes_situacao"
                        v-model="filtro_local.situacao"
                        item-text="text"
                        item-value="value"
                        label="Situação"
                        placeholder="Situação"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        class="mr-2"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <!-- Tipo Parcela ----------------------- -->
                      <v-select
                        :items="opcoes_tipo_parcela"
                        v-model="filtro_local.tipo_parcela"
                        item-text="text"
                        item-value="value"
                        label="Tipo Parcela"
                        placeholder="Tipo Parcela"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        class="mr-2"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <!-- Parcela ----------------------- -->
                      <v-select
                        :items="opcoes_parcela"
                        v-model="filtro_local.opcoes_parcela"
                        item-text="text"
                        item-value="value"
                        label="Período da Parcela"
                        placeholder="Período da Parcela"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class=""
                      ></v-select>
                    </v-col>
</v-row>
<v-row no-gutters class="linhas">
                    <v-col md="3" disabled>
                      <!-- Vencimento Inicial ----------------------- -->
                      <DatePicker
                        id="txtVencInicial"
                        ref="txtVencInicial"
                        v-model="filtro_local.parc_dt_inicial"
                        label="Dt Venc. Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Vencimento Final ----------------------- -->
                      <DatePicker
                        id="txtVencFinal"
                        ref="txtVencFinal"
                        v-model="filtro_local.parc_dt_final"
                        label="Dt Venc. Final"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                        <!-- Valor Inicial ----------------------- -->
                        <v-text-field
                          id="txtValorInicial"
                          ref="txtValorInicial"
                          dense
                          v-model="filtro_local.parc_valor_inicial"
                          cache-items
                          flat
                          label="Valor Inicial"
                          placeholder="Valor Inicial"
                          outlined
                          filled
                          type="number"
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                          class="mr-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col md="3">
                        <!-- Valor Final ----------------------- -->
                        <v-text-field
                          id="txtValorFinal"
                          ref="txtValorFinal"
                          dense
                          v-model="filtro_local.parc_valor_final"
                          cache-items
                          flat
                          label="Valor Final"
                          placeholder="Valor Final"
                          outlined
                          filled
                          type="number"
                          background-color="#FFF"
                          clearable
                          tabindex="0"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
</v-row>
<v-row no-gutters class="linhas">
                    <v-col md="3">
                      <!-- Data de Pagamento Inicial ----------------------- -->
                      <DatePicker
                        id="txtPagamentoInicial"
                        ref="txtPagamentoInicial"
                        v-model="filtro_local.parc_dt_pagto_inicial"
                        label="Dt Pagto Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Data de Pagamento Final ----------------------- -->
                      <DatePicker
                        id="txtPagamentoFinal"
                        ref="txtPagamentoFinal"
                        v-model="filtro_local.parc_dt_pagto_final"
                        label="Dt Pagto Final"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Valor Pago Inicial ----------------------- -->
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_pagto_inicial"
                        cache-items
                        flat
                        label="Valor Pagto Inicial"
                        placeholder="Valor Pagto Inicial"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2" />
                    </v-col>

                    <!-- Valor Pago Final ----------------------- -->
                    <v-col md="3">
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_pagto_final"
                        cache-items
                        flat
                        label="Valor Pagto Final"
                        placeholder="Valor Pagto Final"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="" />
                    </v-col>
</v-row>
<v-row no-gutters class="linhas">
                    <v-col md="6">
                      <!-- Tipo Impressão ----------------------- -->
                      <v-select
                        :items="opcoes_tipo_impressao"
                        v-model="filtro_local.tipo_impressao"
                        item-text="text"
                        item-value="value"
                        label="Tipo Impressão"
                        placeholder="Tipo Impressão"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        class="mr-2"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <!-- Impressao Inicial ----------------------- -->
                      <DatePicker
                        id="txtImpressaoInicial"
                        ref="txtImpressaoInicial"
                        v-model="filtro_local.impressao_inicial_dt"
                        label="Impressao Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Impressao Final ----------------------- -->
                      <DatePicker
                        id="txtImpressaoFinal"
                        ref="txtImpressaoFinal"
                        v-model="filtro_local.impressao_final_dt"
                        label="Impressao Final"
                        placeholder="dia/mês/ano"
                        class=""
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
</v-row>
<v-divider></v-divider>
                    <!-- <v-col md="9"> -->
                      <!-- Proprietário ------------------------ -->
                      <!-- <v-text-field
                        id="txtProprietario"
                        ref="txtProprietario"
                        dense
                        v-model="filtro_local.proprietario"
                        cache-items
                        flat
                        label="Proprietário"
                        placeholder="Proprietário (nome ou cpf)..."
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                         
                      >
                      </v-text-field>
                    </v-col> -->

                </v-container>

                  <!-- Botao Limpar ------------------------------------>
                  <div class="px-4 pb-2 d-flex justify-space-between"
                       v-bind:style="{ 'background-color':COR_SECUNDARIA }">
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      outlined
                      tabindex="-1"
                      :small="isMobile"
                      @click="limpa_todos()"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-close</v-icon
                      >
                      <span>Limpar Todos</span>
                    </v-btn>
                    
                    <!-- Botao Consultar ------------------------------------>
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      tabindex="-1"
                      :small="isMobile"
                      @click="busca(1)"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-magnify</v-icon
                      >
                      <span>Consultar</span>
                    </v-btn>
                  </div>  

              </v-menu>
          </v-container>
          <!-- ----------------------------------------------- -->

          <v-spacer></v-spacer>

          <!-- Botão Transferir ------------------------------- -->
          <v-btn
            v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_alterar') &&
                  acao == 'SELECIONAR' &&
                  total_parc_selecionado > 0"                   
            @click="selecionar()"
            class="text-white mr-9 mt-1"
            :color="COR_PRINCIPAL"
            elevation="0"
            :small="isMobile"
          >
            <v-icon class="mr-1" color="green accent-2">mdi-cash-refund</v-icon>
            <span class="text-none">Transferir</span>
          </v-btn>

          <!-- Botão  ------------------------------- -->
          <v-btn  
            v-show="false"
            v-on:click="novo()"
            class="text-white mr-5 mt-1"
            :color="COR_PRINCIPAL"
            elevation="0"
            :small="isMobile"
          >
            <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
            <span class="text-none">Novo</span>
          </v-btn>

          <!-- Botão Opções ------------------------------- -->
          <v-menu v-if="acao != 'SELECIONAR'" offset-y style="z-index: 10;">
            <template v-slot:activator="{ on, attrs }">
              <v-btn  class="text-white mt-1 mb-n2 mr-2"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  >
                <v-icon class="mr-1" color="light-blue accent-2"
                  >mdi-view-headline</v-icon
                >
                <span>Opções</span>
              </v-btn>
            </template>

            <!-- Botões do Opções -->
            <v-list class="">
              <v-list-item v-on:click="novo()">
                <v-list-item-title class="" >
                  <v-icon color="green" class="mr-2 icon-menu">mdi-plus</v-icon>
                  Novo
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click=";">
                <v-list-item-title class="" >
                  <v-icon color="blue" class="mr-2 icon-menu">mdi-pencil</v-icon>
                  Editar
                </v-list-item-title>
              </v-list-item>

                <!-- Botão de Relatórios Automáticos por aplicação -->
                <v-list-item @click=";">
                  <v-menu offset-y
                      expand-icon="mdi-chevron-down"
                      on-icon="mdi-chevron"
                      off-icon="mdi-chevron"
                      indeterminate-icon="mdi-chevron"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                          elevation="0"
                          v-bind="attrs"
                          v-on="on"
                          >
                        <v-icon color="gray">mdi-chevron-down</v-icon>
                        <v-icon color="blue" class="mr-2 icon-menu">mdi-printer-outline</v-icon>
                        <span>Relatórios</span>
                      </v-list-item-title>
                    </template>
                    <v-list>
                      <MenuRelatorios
                      :aplicacao_nome="$options.name"/>
                    </v-list>
                  </v-menu>
                </v-list-item>

            </v-list>
          </v-menu>

          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>

      <v-row class=" mt-n11 w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

          <!-- Conteiner  -->
          <v-data-table
            id="dataTable"
            ref="dataTable"
            v-model="selected"
            selectable-key="isSelectable"
            :headers="headers"
            no-sorting
            :loading="loading"
            fixed-header
            dense
            :height="tableHeight"
            hide-default-footer
            :hide-default-header="true"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta"
            no-results-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta`"
    				:items="filteredDesserts"
            style="white-space: nowrap"
    				show-select
            item-key="cod_empreendcobranca"
            :items-per-page="20"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'disable-items-per-page': true,
              'show-items-per-page': false,
              'items-per-page-text': '',
              'rows-per-page-items':null
            }"
            class="data-table"
            :page="currentPage || 1"
          >

          <template v-slot:header >
            <thead >
              <tr>
                <th v-for="h in headers" :key="h.value" :class="h.class" :style="h.style">
                  <v-checkbox
                    v-if="h.value == 'checkbox'"
                    show-select
                    hide-details
                    class="checkbox"
                    color="primary"
                    @change="selecionaTodos($event)"
                  />
                  <span v-else class="d-flex flex-nowrap body-2 font-weight-bold">{{h.text}}</span>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{index, item}">
              <tr>
                <td @click="store_ContasReceber.dialogFiltroAvancado = false">
                  <v-checkbox
                    v-model="item.value"
                    show-select
                    hide-details
                    class="checkbox"
                    @click="rodape()"
                  />
                </td>

                <td style="max-width: 40px; padding: 0;">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-n4"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="d-none">
                      <v-list-item
                        v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_alterar') &&
                              acao == 'SELECIONAR' && !item.cod_extrato_movimento && !itens_array_pai.includes(item.cod_empreendcobranca)"
                        @click="selecionar()">
                        <!-- Botao Editar -->
                        <v-list-item-title>
                          <v-icon color="primary" class="mr-2 icon-menu">mdi-arrow-left-circle</v-icon>
                            Selecionar este lançamento
                        </v-list-item-title>
                      </v-list-item>

                      <v-divider v-if="acao == 'SELECIONAR' && !item.cod_extrato_movimento && !itens_array_pai.includes(item.cod_empreendcobranca)" class="my-3"></v-divider>

                      <v-list-item  @click="() => {}"
                        v-if="item.empreendcobr_situacao == 'Aberto'">
                        <!-- Botao Editar -->
                        <v-list-item-title class="" v-on:click="editar(item)" >
                          <v-icon color="green" class="mr-2 icon-menu">mdi-pencil</v-icon>
                           Editar
                        </v-list-item-title>
                      </v-list-item>

                      <!-- <v-list-item  @click="() => {}"
                          v-if="item.empreendcobr_situacao == 'Aberto'">
                        <v-list-item-title
                          v-on:click="pagar(item)"
                          ><v-icon color="green" class="mr-2 icon-menu"
                            >mdi-cash-multiple</v-icon
                          >
                          Pagar
                        </v-list-item-title>
                      </v-list-item> -->
                      
                      <v-list-item  @click="() => {}"
                          v-if="item.empreendcobr_situacao == 'Aberto'">
                        <v-list-item-title
                          v-on:click="dialog_excluir = true"
                          ><v-icon color="blue" class="mr-2 icon-menu"
                            >mdi-printer</v-icon
                          >
                          Imprimir
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item  @click="() => {}"
                          v-if="item.empreendcobr_situacao == 'Aberto'">
                        <v-list-item-title
                          v-on:click="dialog_excluir_confirmacao_1 = item.cod_empreendcobranca"
                          ><v-icon color="red" class="mr-2 icon-menu"
                            >mdi-delete</v-icon
                          >
                          Excluir
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item  @click="() => {}"
                          v-if="item.empreendcobr_situacao == 'Pago'">
                        <v-list-item-title
                          v-on:click="dialog_estornar_confirmacao_1 = item"
                          ><v-icon color="red" class="mr-2 icon-menu"
                            >mdi-arrow-u-left-top-bold</v-icon
                          >
                          Estornar
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item  @click="() => {}"
                          v-if="item.empreendcobr_situacao == 'Pago'">
                        <v-list-item-title
                          v-on:click="pagar(item)"
                          ><v-icon color="blue" class="mr-2 icon-menu"
                            >mdi-magnify</v-icon
                          >
                          Visualizar
                        </v-list-item-title>
                      </v-list-item>

                      <!--Documento -->
                      <!-- v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasReceber_visualizar')" -->
                      <v-list-item
                          @click="documentos(item)">
                          <v-list-item-title >
                            <v-icon color="green" class="mr-2 icon-menu">mdi-badge-account</v-icon>Documentos
                          </v-list-item-title>
                        </v-list-item>

                    </v-list>
                  </v-menu>
                </td>
                <td align="right">
                  <v-tooltip top v-if="item.cod_extrato_movimento">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-link-variant</v-icon>
                      </template>
                      <span>Cobrança Conciliada</span>
                    </v-tooltip>
                    <v-tooltip v-if="Object.keys(item.docs).length > 0" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-receipt-text</v-icon>
                      </template>
                      <span v-for="h in Object.entries(item.docs)" :key="h[0]">{{ h[0] }}: {{ h[1] }}<br></span>
                    </v-tooltip>
                    {{ item.cod_empreendcobranca }}
                </td>

                <td align="center">

                  <span
                    v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() >= moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                    class="status_aberto_vencer"
                  ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-check</v-icon>{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() < moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                    class="status_aberto_vencida"
                  ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-alert-octagon-outline</v-icon>{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Pago'"
                    class="status_pago"
                    >{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Rescindido'"
                    class="status_rescindido"
                    >{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Inativo'"
                    class="status_inativo"
                    >{{ item.empreendcobr_situacao }}
                  </span>
                </td>
                <td style="white-space: nowrap;">
                      <v-btn
                        @click="expandRow(index, item)"
                        icon
                        light
                        shaped
                        height="27px"
                        width="27px"
                        :style="{
                                  backgroundColor : item.empreendcobr_impressao_via > 0 ? '#D3ECFF !important' : '',
                                  color           : item.empreendcobr_impressao_via > 0 ? '#507090 !important' : '',
                                  }"

                      >
                      {{ item.empreendcobr_impressao_via }}
                      </v-btn>
                </td>
                
                <td>{{ item.empreendcobr_descricao.substr(0, 4).initCap() }} ({{ item.empreendcobr_parcela_inicial }}/{{ item.empreendcobr_parcela_final }})</td>
                <td>{{ formatDate(item.empreendcobr_parc_dt) }}</td>
                <td align="center">{{ item.empreendcobr_parc_valor ? formatPrice(item.empreendcobr_parc_valor) : '' }}</td>
                <!-- <td align="center">{{ item.empreendcobr_parc_acres_valor ? formatPrice(item.empreendcobr_parc_acres_valor) : '' }}</td> -->
                <!-- <td align="center">{{ item.empreendcobr_parc_desc_valor ? formatPrice(item.empreendcobr_parc_desc_valor) : '' }}</td> -->
                <!-- <td align="center">{{ item.empreendcobr_pagto_valor_multa ? formatPrice(item.empreendcobr_pagto_valor_multa) : '' }}</td> -->
                <!-- <td align="center">{{ item.empreendcobr_pagto_valor_juros ? formatPrice(item.empreendcobr_pagto_valor_juros) : '' }}</td> -->
                <!-- <td align="center">{{ item.empreendcobr_parc_valor_final ? formatPrice(item.empreendcobr_parc_valor_final) : '' }}</td> -->
                <td>{{ item.empreendcobr_pagto_dt ? formatDate(item.empreendcobr_pagto_dt) : '' }}</td>

                <td align="right">
                    <v-tooltip top v-if=" item.empreendcobr_parc_acres_valor ||
                                          item.empreendcobr_parc_desc_valor ||
                                          item.empreendcobr_pagto_valor_multa ||
                                          item.empreendcobr_pagto_valor_juros
                                          ">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" color="red">mdi-cash</v-icon>
                      </template>
                      <span v-if="item.empreendcobr_parc_acres_valor"   align="right">{{ 'Acréscimo: ' + formatPrice(item.empreendcobr_parc_acres_valor)}}<br></span>
                      <span v-if="item.empreendcobr_parc_desc_valor"    align="right">{{ 'Desconto: ' + formatPrice(item.empreendcobr_parc_desc_valor)  }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_multa"  align="right">{{ 'Multa: ' + formatPrice(item.empreendcobr_pagto_valor_multa)   }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_juros"  align="right">{{ 'Juros: ' + formatPrice(item.empreendcobr_pagto_valor_juros)   }}<br></span>
                    </v-tooltip>
                    {{ item.empreendcobr_pagto_valor ? formatPrice(item.empreendcobr_pagto_valor) : '' }}
                  </td>

                <td style="width:280px;">
                  <span class="width-nome-customer">{{ item.empreend_venda.pessoa_venda.pessoa_nome }}</span>
                </td>

                <td>
                  <span class="customer">{{ item.empreend_venda.empreendvenda_imovel.quadra }} / {{ item.empreend_venda.empreendvenda_imovel.lote }}</span>
                </td>

                <td align="right">
                  {{ item.empreend_venda.empreendvenda_contrato }}
                </td>

                <td>
                  <span class="width-empreendimento customer">{{ item.empreend_venda.vendas.empreend_nome }}</span>
                </td>

                <td align="right">
                  {{ item.cod_empreendvenda }}
                </td>

                <td style="width:280px; white-space: nowrap;">
                  <span class="width-adress">{{ item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_lograd + ", " + item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_num + ", " + item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_bairro }}</span>
                  <!-- {{ item.cep }} - {{ item.cidade }} -->
                </td>

                <!-- <td >
                  {{ item.meses }}
                </td> -->
              <!-- <tr>
                <div>
                  <tr style="white-space: nowrap;">
                    <td>
                      Boletos Impressos:
                    </td>
                    <td>
                      1
                    </td>
                  </tr>
                </div>
                <div>
                  <tr style="white-space: nowrap;">
                    <td>
                      Boletos Antecipação:
                    </td>
                    <td>
                      2
                    </td>
                  </tr>
                </div>
              </tr> -->

              <!-- <tr v-if="isMobile">
                <td style="width: auto">
                  <div class="mt-1" style="font-size: 14px; font-weight:500">
                    {{ item.pessoa_nome }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.pessoa_email }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.fone_celular1 }}
                  </div>
                </td>
              </tr> -->
              </tr>

              <!-- SubGrade -->
              <tr  v-if="expanded===index && item.empreendcobr_impressao_via > 0" class="data-table-impressos-fundo">
                <td colspan="13">
                <v-data-table
                  class="data-table ml-10 data-table-impressos"
                  :items="boletos"
                  hide-default-footer
                  single-expand
                >
                <!-- :expanded.sync="expanded" -->
                  <template v-slot:header >
                    <thead >
                      <tr>
                        <th v-for="h_i in headers_impressos" :key="h_i.value" :class="h_i.class" :style="h_i.style">
                          <span class="d-flex flex-nowrap body-2 font-weight-bold">{{h_i.text}}</span>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template #item="{ item }">
                    <tr>
                      <td style="max-width: 40px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="grey"
                              dark
                              icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-n4"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list class="">
                            <v-list-item @click="() => {}"
                              >
                              <v-list-item-title
                                @click="baixarBoleto(item)"
                                ><v-icon color="green" class="mr-2 icon-menu"
                                  >mdi-cash-multiple</v-icon
                                >
                                Pagar Boleto
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item  @click="() => {}"
                              >
                              <v-list-item-title
                                @click="reimprimirBoleto(item)"
                                ><v-icon color="blue" class="mr-2 icon-menu"
                                  >mdi-printer</v-icon
                                >
                                Reimprimir Boleto
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item  @click="() => {}"
                              >
                              <v-list-item-title
                                @click="excluirBoleto(item)"
                                ><v-icon color="red" class="mr-2 icon-menu"
                                  >mdi-delete</v-icon
                                >
                                Excluir Boleto
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                      <td>{{item.empreendcobr_impressao_via}}</td>
                      <td>{{item.empreendcobr_impressao_tipo}}</td>
                      <td>{{formatDateTime(item.empreendcobr_impressao_dt)}}</td>
                      <td>{{item.cod_empreendcobranca_impressao}}</td>
                      <td>{{item.empreendcobr_impr_valor_final}}</td>
                      <td>{{formatDate(item.empreendcobr_impr_vencto_dt)}}</td>
                      <td>{{`${item.empreendcobr_nossonumero} - ${item.empreendcobr_nossonumero_dig}`}}</td>
                      <!-- <td>
                        <v-btn  
                          @click="excluirBoleto(item.cod_empreendcobranca_impressao)"
                          class="text-white mt-1"
                          :color="COR_PRINCIPAL"
                          elevation="0"
                          :small="isMobile"
                        >
                        <v-icon class="mr-1" color="green accent-2">mdi-delete</v-icon>
                        <span class="">Excluir Boleto</span>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn  
                          @click=";"
                          class="text-white mt-1"
                          :color="COR_PRINCIPAL"
                          elevation="0"
                          :small="isMobile"
                        >
                        <v-icon class="mr-1" color="green accent-2">mdi-printer</v-icon>
                        <span class="">Reimprimir Boleto</span>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn  
                          @click="baixarBoleto(item.cod_empreendcobranca_impressao)"
                          class="text-white mt-1"
                          :color="COR_PRINCIPAL"
                          elevation="0"
                          :small="isMobile"
                        >
                        <v-icon class="mr-1" color="green accent-2">mdi-cash-multiple</v-icon>
                        <span class="">Baixar via Boleto</span>
                        </v-btn>
                      </td> -->
                    </tr>
                  </template>
                </v-data-table>
                <br>
                </td>
              </tr>

            </template>

          </v-data-table>

            <!-- LEGENDA COBRANÇAS -------------------------------------------------------->
            <div class="mt-2 mb-1 ml-4">
              <table class="d-flex justify-start">
                <tbody class="">
                  <tr class="text sticky-table-footer-desktop d-flex mb-2">
                    <td align="left" style="display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_a_vencer">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <!-- <span class="body-1 mr-2" style="color:#E0E0E0">({{total_aberto_parc}} / R$ {{ formatNumber(total_aberto_valor)}})</span> -->
                            <span class="mr-1 font-weight-thin body-2" style="color:#fff">À vencer (Qtde / Valor):</span>
                            <span class="mr-2 font-weight-medium body-1 " style="color:#fff"> {{total_aberto_parc}} / R$ {{ formatNumber(total_aberto_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas em dia</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_vencidas">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-alert-octagon-outline</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Vencidas (Qtde / Valor):</span>
                            <span class="mt-n2 mr-2 font-weight-medium body-1" style="color:#fff"> {{total_vencidas_parc}} / R$ {{ formatNumber(total_vencidas_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas com pagamento em atraso</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_pagos">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Pagos (Qtde / Valor):</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_pago_parc}} / R$ {{ formatNumber(total_pago_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças pagas já baixadas no sistema</span>
                      </v-tooltip> 
                    </td>
                    <td v-if="total_parc_selecionado > 0" align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_selecionados">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Seleção (Qtde / Aberto / Pago):</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_parc_selecionado}} / R$ {{ formatNumber(total_parc_valor_selecionado)}} / R$ {{ formatNumber(total_pago_valor_selecionado)}}</span>
                          </span>
                        </template>
                        <span>Cobranças selecionadas</span>
                      </v-tooltip> 
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

      <!-- Paginacao ------------------------------ -->
        <div class="paginacao d-flex justify-end align-center" style="background-color: #FFFFFF;">
        <span style="color:grey; font-size:14px" class="mr-3">{{count_dados}} linhas</span>
        <v-pagination
          id="pagination"
          ref="pagination"
          v-model="currentPage"
          :length="store_ContasReceber.paginas"
          total-visible="5"
          @input="handlePageChange"
          aria-controls="dataTable"
          class="mt-n1"
          style="color:grey"
          />
      </div>

    <!-- Dialog Excluir ----------------------- -->
    <v-dialog
      :value="dialog_excluir_confirmacao_1"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
      <v-card-title class="text-h6">
        Tem certeza que deseja EXCLUIR ?
      </v-card-title>
      <!-- <v-card-text v-if="store_Proposta.pessoa_selecionado.pessoa_cpf">
        <span style="font-size: 15px; font-weight:600">{{store_Proposta.pessoa_selecionado.pessoa_nome}}</span><br>
        {{maskCpfCnpj(store_Proposta.pessoa_selecionado.pessoa_cpf)}}<br>
        {{store_Proposta.pessoa_selecionado.pessoa_email}}<br>
        {{store_Proposta.pessoa_selecionado.fone_celular1}}<br>
      </v-card-text>
      <v-card-text v-if="store_Proposta.pessoa_selecionado.pessoa_cnpj">
        <span style="font-size: 15px; font-weight:600">{{store_Proposta.pessoa_selecionado.pessoa_nome}}</span><br>
        {{maskCpfCnpj(store_Proposta.pessoa_selecionado.pessoa_cnpj)}}<br>
        {{store_Proposta.pessoa_selecionado.pessoa_email}}<br>
        {{store_Proposta.pessoa_selecionado.fone_celular1}}<br>
      </v-card-text> -->
      <v-card-actions class="d-flex justify-center">
        <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_excluir_confirmacao_1 = null"
          >
            Cancelar
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="dialog_excluir_confirmacao_2 = dialog_excluir_confirmacao_1; dialog_excluir_confirmacao_1 = null;"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Excluir - CONFIRMAÇÃO ------------------------->
    <v-dialog
      :value="dialog_excluir_confirmacao_2"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
        <v-card-title class="text-h6 justify-center">
        ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
      </v-card-title>
      <v-card-title class="text-h6">
        Esta operação é IRREVERSÍVEL, deseja continuar?
      </v-card-title>
      <v-card-actions class="d-flex justify-left">
        
          <v-btn
            class="btn white--text"
            color="error accent-4"
            :loading="loading_excluir"
            @click="excluir(dialog_excluir_confirmacao_2)"
          >
            Excluir
          </v-btn>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_excluir_confirmacao_2 = null"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Estornar ------------------------->
    <v-dialog
      :value="dialog_estornar_confirmacao_1"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
      <v-card-title class="text-h6">
        Tem certeza que deseja ESTORNAR o pagamento ?
      </v-card-title>
      <v-card-actions class="d-flex justify-center">
        <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_estornar_confirmacao_1 = null"
          >
            Cancelar
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="dialog_estornar_confirmacao_2 = dialog_estornar_confirmacao_1; dialog_estornar_confirmacao_1 = null"
          >
            Estornar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Estornar - CONFIRMAR ------------------------->
    <v-dialog
      :value="dialog_estornar_confirmacao_2"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
        <v-card-title class="text-h6 justify-center">
        ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
      </v-card-title>
      <v-card-title class="text-h6">
        Esta operação é IRREVERSÍVEL, deseja continuar ?
      </v-card-title>
      <v-card-actions class="d-flex justify-left">
          <v-btn
            class="btn white--text"
            color="error accent-4"
            :loading="loading_excluir"
            @click="estornar(dialog_estornar_confirmacao_2)"
          >
            Estornar
          </v-btn>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_estornar_confirmacao_2 = null"
          >
            Cancelar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Editar/Pagar -->
    <v-container v-if="dialog_novo_editar">
      <v-dialog
        persistent
        v-model="dialog_novo_editar"
        transition="dialog-bottom-transition"
        max-width="800"
        min-width="520">
        <ContasReceberModal
          :dialog_novo_editar.sync="dialog_novo_editar"
          :dados_editar="dados_editar"
          @atualizaRegistro="atualiza_registro"
        />
      </v-dialog>
    </v-container>

    <!-- Dialog Novo -->
    <v-container v-if="dialog_novo">
      <v-dialog
        v-model="dialog_novo"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        max-width="600"
        min-width="520"
      >
        <ContasReceberNovoModal
          :dialog_novo.sync="dialog_novo"
          :dados_editar="dados_editar"
          @atualizaRegistro="atualiza_registro"
          height="900px"
        />
      </v-dialog>
    </v-container>

    <!-- Dialog Documentos -->
    <v-container v-if="dialog_documentos">
      <v-dialog
        persistent
        v-model="dialog_documentos"
        transition="dialog-bottom-transition"
        width="80%"
        overfl
        style="overflow-x:hidden">
        <v-card elevation="0" class="pa-0" style="border-radius: 0;">
          <v-card-title 
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between pa-1">
            <div>
              <div>
                <span class="title-page text-white pl-2">Cobrança nº {{dados_documentos.cod_empreendcobranca}}</span>
                <span class="title-page-menor text-white ml-2 mr-2"> - </span>
                <span class="title-page-menor text-white">{{dados_documentos.empreendcobr_descricao}} ( {{dados_documentos.empreendcobr_parcela_inicial}} / {{dados_documentos.empreendcobr_parcela_final}} )</span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Pago'" class="status_ativo ml-3">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Aberto'" class="status_pendente ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Rescindido'" class="status_cancelado ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Inativo'" class="status_cancelado ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                </div>
            </div>
            <v-btn @click="dialog_documentos = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="wrapper pa-0 pagar-form" >
            <v-divider dark></v-divider>

            <div :style="{ 'background-color':COR_PRINCIPAL }">
              <!-- Cabecalho -------------------- -->
              <v-row style="width: 100%" v-if="dados_documentos.tipo == 'CONTAS A PAGAR'" class="mt-0 mx-0">
                <v-col class="py-1 pr-0">
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Favorecido:
                  </label>
                  <label
                    style="color:white;font-size:15px;"
                    class="mr-4">
                      {{ dados_documentos.cobr_pess ? dados_documentos.cobr_pess?.pessoa_nome : dados_documentos.pessoa_nome }} 
                  </label>
                  <label style="color:white;font-size:15px;" class="mr-4">|</label>
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
                  </label>
                    <label
                    style="color:white;font-size:15px;"
                    class="mr-5">
                    {{ dados_documentos.cobr_pess ? dados_documentos.emp_cobr?.empreend_nome : dados_documentos.empreend_nome}}
                  </label>
                </v-col>
              </v-row>
              <v-row v-if="dados_documentos.tipo == 'CONTAS A RECEBER'" class="ml-1 mt-0 mx-0">
                <v-col class="pa-1">
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Devedor:
                  </label>
                  <label
                    style="color:white;font-size:15px;"
                    class="mr-4">
                      {{ dados_documentos.empreend_venda.pessoa_venda.pessoa_nome }} 
                  </label>
                  <label style="color:white;font-size:15px;" class="mr-4">|</label>
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
                  </label>
                    <label
                    style="color:white;font-size:15px;"
                    class="mr-5">
                    {{ dados_documentos.empreend_venda.vendas.empreend_nome}}
                  </label>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        
        <GedDocumentos
          :dados_documentos="dados_documentos"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :paramsTipoDocumento="paramsTipoDocumento"
          :paramBusca="paramBusca"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_documentos = false" />
      </v-dialog>
    </v-container>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{dialog_excluir_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_excluir_alert = false"
          class="text-none"
          dark
        >
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="dialog_estornar_alert"
      :timeout="4000"
      :color="dialog_estornar_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{dialog_estornar_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_estornar_alert = false"
          class="text-none"
          dark>
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    
  </div>
 
</template>

<script>
import { API, API_CNAB } from "../../../services/API"
import store_usuario from "../../../store/store_usuario";
import store_ContasReceber from "./store_ContasReceber";
import store_site from "../../../../src/store/store_site"
import { ContasReceberGet } from "../../../services/erp";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO } from "../../../services/constantes";
import { maskCpfCnpj, formatDate, formatDateTime, formatNumber, formatPrice } from "../../../services/funcoes";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import ContasReceberModal from "./ContasReceberModal.vue";
import ContasReceberNovoModal from "./ContasReceberNovoModal.vue";
import moment, { now } from 'moment';
import { saveAs } from 'file-saver';
import { AbreRelatorioExterno, Relatorio } from "../../../services/global";
import exportFromJSON from "export-from-json";
import MenuRelatorios from "../../ComponentesGlobais/MenuRelatorios/MenuRelatorios.vue"
import GedDocumentos from "../../Ged/GedDocumentos.vue";
//---------------------- fim teste download excel --------

export default {
  name: "ContasReceber",

  props: ['acao', 'dialog_ContasReceber', 'dialog_lancamentos', 'itens_array_pai', 'parc_valor'],

  components: {
    DatePicker,
    ContasReceberModal,
    ContasReceberNovoModal,
    GedDocumentos,
    MenuRelatorios
  },

  data() {
    return {
      user : { username : null }, 
 
      initDate                             : null,
      endDate                              : null,
      store_usuario                        : store_usuario,
      store_ContasReceber                  : store_ContasReceber,
      store_site                           : store_site,
 
      ContasReceberGet                     : ContasReceberGet,
 
      empreendimentos                      : [],
      boletos                              : [],
 
      maskCpfCnpj                          : maskCpfCnpj,
      formatDate                           : formatDate,
      formatDateTime                       : formatDateTime,
      formatNumber                         : formatNumber,
      formatPrice                          : formatPrice,
 
      loading                              : false,
      search                               : null,
      search_input_focus                   : false,
 
      loading_excluir                      : false,
      loading_relatorio                    : false,
      //filtro                             : null,

      expanded                             : null,
      toolsIndex                           : null,
 
      currentPage                          : 1,
      count_dados                          : null,
      total_aberto_parc                    : 0,
      total_aberto_valor                   : 0,
      total_vencidas_parc                  : 0,
      total_vencidas_valor                 : 0,
      total_pago_parc                      : 0,
      total_pago_valor                     : 0,
      total_parc_selecionado               : 0,
      total_parc_valor_selecionado         : 0,
      total_pago_valor_selecionado         : 0,

      isActiveVencidos                      : false,
      isActiveHoje                          : false,
      isActiveSemana                        : false,
      isActiveMes                           : false,
 
      situacao_atual                       : null,
 
      COR_PRINCIPAL                        : COR_PRINCIPAL,
      COR_SECUNDARIA                       : COR_SECUNDARIA,
      COR_SECUNDARIA_2                     : COR_SECUNDARIA_2, 
      COR_SUBTITULO                        : COR_SUBTITULO,
      accentColor                          : '#f00',
 
      dialog_excluir                       : false,
      dialog_excluir_alert                 : false,
      dialog_excluir_msg                   : false,
      dialog_excluir_message               : null,
 
      dialog_excluir_confirmacao_1         : null,
      dialog_excluir_confirmacao_2         : null,
      dialog_excluir_alert_confirmacao     : false,
      dialog_excluir_msg_confirmacao       : false,
      dialog_excluir_message_confirmacao   : null,
 
      dialog_estornar                      : false,
      dialog_estornar_alert                : false,
      dialog_estornar_msg                  : false,
      dialog_estornar_message              : null,
 
      dialog_estornar_confirmacao_1        : false,
      dialog_estornar_confirmacao_2        : false,
      dialog_estornar_alert_confirmacao    : false,
      dialog_estornar_msg_confirmacao      : false,
      dialog_estornar_message_confirmacao  : null,

      dialog_pagar                         : false,
      dialog_pagar_msg                     : false,
      dialog_pagar_message                 : null,

      dialogIndiceCadastro                 : false,
      dialog_novo_editar                   : false,
      dialog_novo                          : false,

      dialog_documentos                    : false,
      cod_empreendcobranca_aux             : null,
      dados_documentos                     : null,
      
      selected: [],
      /* Título container-rounded */
      headers: [
        { text: "", value: "checkbox", style: "max-width:40px!important" },
        { text: "", value: "1", style: "max-width:40px!important" },
        { text: "#", value: "cod_empreendcobranca"},
        { text: "Situação", value: "empreendcobr_situacao" },
        { text: "Via", value: "empreendcobr_impressao_via" },
        { text: "Parcelas", value: "quant_parcelas" },
        { text: "Dt. Venc.", value: "empreendcobr_parc_dt" },
        { text: "Parc. Valor", value: "empreendcobr_parc_valor" },
        // { text: "Acréscimo", value: "empreendcobr_parc_acres_valor" },
        // { text: "Desconto", value: "empreendcobr_parc_desc_valor" },
        // { text: "Multa", value: "empreendcobr_pagto_valor_multa" },
        // { text: "Juros", value: "empreendcobr_pagto_valor_juros" },
        // { text: "Valor final", value: "empreendcobr_parc_valor_final" },
        { text: "Dt. Pagto", value: "empreendcobr_pagto_dt" },
        { text: "Valor Pagto", value: "empreendcobr_pagto_valor", style: "width:auto!important" },
        { text: "Cliente", value: "cliente", style: "min-width:330px!important" },
        { text: "Unidade", value: "unidade" },
        { text: "Contrato", value: "numero_contrato" },
        { text: "Empreendimento", value: "empreendimento" },
        { text: "Proposta", value: "numero_proposta" },
        { text: "Endereço", value: "endereco" },
        // { text: "Meses", value: "meses" },
      ],
      headers_impressos: [
        { text: "",               value: "", style: "max-width:40px!important" },
        { text: "Via",            value: "empreendcobr_impressao_via",},
        { text: "Tipo",           value: "empreendcobr_impressao_tipo" },
        { text: "Data Impressão", value: "empreendcobr_impressao_dt"},
        { text: "Cód. Impressão", value: "cod_empreendcobranca_impressao" },
        { text: "Valor",          value: "empreendcobr_impr_valor_final" },
        { text: "Vencto Boleto",  value: "empreendcobr_impr_vencto_dt" },
        { text: "Nosso Número",   value: "empreendcobr_nossonumero" },
      ],
			filters: {
        //a: [],
        //b: [],
        empreendcobr_situacao: [],
        //cod_empreendcobranca: [],
				empreendcobr_impressao_via: [],
				parcela: [],
        parc_entr_dt: [],
				parc_valor_final: [],
			},

      /* Tabela Consulta */
      cobrancas: [],

      /* Registro que será Incluido ou Editado */
      dados_editar : {},

      /* Opções */
      opcoes_situacao: [
                          { "id": 1, text: "Aberto", value: "Aberto" },
                          { "id": 2, text: "Pago", value: "Pago" },
                          { "id": 3, text: "Rescindido", value: "Rescindido" },
                        ],

      opcoes_tipo_parcela:
                        [
                          { "id": 1, text: "Entrada", value: "ENTRADA" },
                          { "id": 2, text: "Parcela", value: "PARCELA" },
                          { "id": 3, text: "Renegociação", value: "RENEGOCIACAO" },
                          { "id": 4, text: "Residuo", value: "RESIDUO" },
                          { "id": 5, text: "Acordo", value: "ACORDO" },
                          { "id": 6, text: "Despesa", value: "DESPESA" },
                          { "id": 7, text: "Manual", value: "MANUAL" },
                        ],
                        
      opcoes_parcela:
                        [
                          { "id": 1, text: "Mensal", value: "Mensal" },
                          { "id": 2, text: "Bimestral", value: "Bimestral" },
                          { "id": 3, text: "Trimestral", value: "Trimestral" },
                          { "id": 4, text: "Semestral", value: "Semestral" },
                          { "id": 5, text: "Anual", value: "Anual" },
                        ],
                        
      opcoes_tipo_impressao:
                        [
                          { "id": 1, text: "Não Impressos", value: "Não Impressos" },
                          { "id": 2, text: "Impressos (Boletos e Antecipações)", value: "Impressos (Boletos e Antecipações)" },
                          { "id": 3, text: "Impressos (Somente Boletos)", value: "Impressos (Somente Boletos)" },
                          { "id": 4, text: "Impressos (Somente Antecipações)", value: "Impressos (Somente Antecipações)" },
                        ],
                        
      opcoes_bloqueados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],
                        
      opcoes_reajustados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],



      /* Inputs e Menus Data */
      menu    : false,
      //venc_final2   : null,
      filtro_local: {},
      
      filtro_mesmo_valor       : false,
      filtro_mesma_data_pagto  : false,
      filtro_mesmo_valor_cnab  : true,

      data_picker : {
        'min-width':'150px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      },

      tree_opcoes : null,
      menu_config : [
                    {
                      "id": "Vendas",
                      "name": "Vendas",
                      "icon": "mdi-folder",
                      "children": [
                        {
                          "id": "Vendas (Período)",
                          "name": "Vendas (Período)",
                          "icon": "mdi-view-headline"
                        }
                      ]
                    },
                    {
                      "id": "Gerencial",
                      "tipo": "relatorio",
                      "icon": "mdi-folder",
                      "path": "/painel_gestor",
                      "name": "Gerencial",
                      "children": [
                        {
                          "id": "Situação das Unidades",
                          "name": "Situação das Unidades",
                          "icon": "mdi-view-headline"
                        }
                      ]
                    }
                  ]

    };
  },

  mounted() {
    if (this.$route.query.ViscomSGI) {
      this.store_site.ViscomSGI = this.$route.query.ViscomSGI;
    }
    else
      this.store_site.ViscomSGI = null;

    // Busca privilegios de acesso ao empreendimento
    this.empreendimento_privilegio_acesso();
    if (this.acao == 'SELECIONAR') {
      this.filtroMesmoValorCNAB();
    }
  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 145;
      else
        if (this.acao == 'SELECIONAR')
          return window.innerHeight - 149 - 120;
        else
          return window.innerHeight - 149 - 92;
    },

  	filteredDesserts() {
      return this.cobrancas.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
		},	

  },

  methods: {

    changeVencimento(val){
      this.isActiveVencidos = false
      this.isActiveHoje = false
      this.isActiveMes = false
      this.isActiveSemana = false
    },
    
    vencendo(dias, vmodel) {
      this.changeVencimento();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
      }
      if (vmodel === 'isActiveHoje') {
        this.isActiveHoje = true
      }
      else if (vmodel === 'isActiveSemana') {
        this.isActiveSemana = true
      }
      else if (vmodel === 'isActiveMes') {
        this.isActiveMes = true
      }
      
      this.filtro_local.parc_dt_inicial = moment().format();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
        this.filtro_local.parc_dt_inicial = null;
      }
      this.filtro_local.situacao = "Aberto";
      let data = moment(); // cria um objeto moment com a data atual
      data = data.add(dias, 'days'); // adiciona dias ao objeto moment
      this.filtro_local.parc_dt_final = data.format(); // exibe a nova data formatada
    },

    fechar() {
      this.$emit('update:dialog_ContasReceber', null);
    },

    selecionar() {
      const itens = this.cobrancas.filter((d) => d.value == true).map((v) => {return v})
      this.$emit('itemSelecionado', itens);
      this.fechar();
    },
    
    filtroMesmoValor() {
      if (this.filtro_mesmo_valor) {
        this.filtro_local.parc_valor_inicial = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc_valor_final   = Math.abs(this.dialog_lancamentos?.valor);
      }
      else {
        this.filtro_local.parc_valor_inicial = null;
        this.filtro_local.parc_valor_final   = null;
      }
      this.busca(1);
    },

    filtroMesmoValorCNAB() {
      if (this.filtro_mesmo_valor_cnab) {
        this.filtro_local.parc_valor_cnab = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc_valor_cnab   = Math.abs(this.dialog_lancamentos?.valor);
      }
      else {
        this.filtro_local.parc_valor_cnab   = null;
      }
      this.busca(1);
    },

    filtroMesmaDataPagto() {
      if (this.filtro_mesma_data_pagto) {
        this.filtro_local.parc_dt_pagto_inicial = this.dialog_lancamentos?.data;
        this.filtro_local.parc_dt_pagto_final   = this.dialog_lancamentos?.data;
      }
      else {
        this.filtro_local.parc_dt_pagto_inicial = null;
        this.filtro_local.parc_dt_pagto_final   = null;
      }
      this.busca(1);
    },

    expandRow(index, item) {
      //console.log('row expanded: ', item);
      if (item.empreendcobr_impressao_via > 0) {
        if (this.expanded != index) {
          this.boletos = [];
          this.expanded = index;
          this.impressos(item.cod_empreendcobranca)
        }
        else {
          this.boletos = [];
          this.expanded = null;
        }
      }
      else {
        this.boletos = [];
        this.expanded = null;
      }
    },

    async export_table(dados, tipo, nome_arquivo) {
      //dados.forEach( e => ({value: "sssssss", ...e}))
      //console.log("this.cobrancas",this.cobrancas)


    const lo_Obj = this.cobrancas.map((e) => ({   
                                                situacao        : e.empreendcobr_situacao,
                                                documento       : e.cod_empreendcobranca,
                                                via             : e.empreendcobr_impressao_via,
                                                parcela         : e.empreendcobr_descricao_texto,
                                                vencimento      : formatDate(e.empreendcobr_parc_dt),
                                                valor           : e.empreendcobr_parc_valor_final,
                                                dt_pagto        : formatDate(e.empreendcobr_pagto_dt),
                                                vl_pagto        : e.empreendcobr_pagto_valor,
                                                cliente         : e.empreend_venda.pessoa_venda.pessoa_nome,
                                                unidade         : e.empreend_venda.empreendvenda_imovel.quadra + ' | ' + 
                                                                  e.empreend_venda.empreendvenda_imovel.lote,
                                                contrato        : e.empreend_venda.empreendvenda_contrato,
                                                empreendimento  : e.empreend_venda.vendas.empreend_nome,
                                                proposta        : e.empreend_venda.ev,
                                                endereco        : e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_lograd + ", " + 
                                                                  e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_num + ", " + 
                                                                  e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_bairro,
                                                }));
      //console.log("lo_Obj",lo_Obj)
      dados = lo_Obj;
      const data = dados;
      //console.log('dadosExcel', data)
      const fileName = nome_arquivo;
      let exportType;
      if (tipo == 'xls')
        exportType = exportFromJSON.types.xls;
      if (tipo == 'csv')
        exportType = exportFromJSON.types.csv;
      if (tipo == 'pdf')
        exportType = exportFromJSON.types.txt;
      if (data)
        exportFromJSON({ data, fileName, exportType });
    },

    //------------ teste download excel -----------------------
    //     exportSheet() {  
    //   const spread = this._spread;  
    //   const fileName = "SalesData.xlsx";  
    //   const sheet = spread.getSheet(0);  
    //   const excelIO = new Excel.IO();  
    //   const json = JSON.stringify(spread.toJSON({  
    //       includeBindingSource: true,  
    //       columnHeadersAsFrozenRows: true,  
    //   }));  
    //   excelIO.save(json, function(_blob_){  
    //     saveAs(blob, fileName);  
    //   }, function (_e_) {   
    //     //console.log(e)   
    //   });  
    // },

    //async cnab_remessa_240_baixar(cod_empreendcobrancacnab){
    //async exportSheet(){
    //  //console.log('entrou aqui', this.dados_editar.data)
    // this.loading_baixando = true;
    // const resposta = await API.get(`cnab_remessa_baixar`, {params:{cod_empreendcobrancacnab}})
    // if (resposta) {
    //   ////console.log('RESPOSTA -- ', resposta);
    //   var a = document.createElement("a"); //Create <a>
    //   a.href = "data:file/txt;base64," + resposta.data.result.arquivo_conteudo; //Image Base64 Goes here
    //   a.download = resposta.data.result.arquivo_nome; //File name Here
    //   a.click(); //Downloaded file
    // }
    // this.loading_baixando = false;
    //},

    limpa_todos() {
      for(let prob in this.filtro_local){
        this.filtro_local[prob] = null
      }
      // this.store_ContasPagar.filtro = []
      this.isActiveVencidos = false
      this.isActiveHoje     = false
      this.isActiveMes      = false
      this.isActiveSemana   = false
    },

    async busca(page) {
      if (page)
        this.currentPage = page;
      this.loading  = true;
      this.menu     = false;
      this.boletos  = [];
      this.expanded = null;
      this.store_ContasReceber.filtro = {...this.filtro_local};
      if (this.store_ContasReceber.filtro.cliente_nome) {
        ////console.log("this.store_ContasReceber.filtro.filtro", this.store_ContasReceber.filtro.cliente_nome)
        this.store_ContasReceber.filtro.cliente_nome = this.store_ContasReceber.filtro.cliente_nome.trim()
      }
      var lo_params = { page: page, ...this.store_ContasReceber.filtro };
      // Limpando o objeto Empreendimento para enviar a API e deixando somente os codigos
      lo_params.cod_empreendimento   = null;
      if (this.store_ContasReceber.filtro.empreendimento) {
        lo_params.cod_empreendimento = this.store_ContasReceber.filtro.empreendimento.cod_empreendimento;
        lo_params.empreendimento     = null;
      }
      //console.log("🚀 ~ file: ContasReceber.vue:2138 ~ busca ~ lo_params:", lo_params)
      if (this.dialog_lancamentos) {
        lo_params.cod_pessoa_conta = this.dialog_lancamentos.cod_pessoa_conta;
        if (this.filtro_mesmo_valor_cnab)
          lo_params.parc_valor_cnab  = this.dialog_lancamentos.valor;
      }
      let la_JSON;
      lo_params.parc_valor_inicial        ? lo_params.parc_valor_inicial        = Number(lo_params.parc_valor_inicial) : null;
      lo_params.parc_valor_final          ? lo_params.parc_valor_final          = Number(lo_params.parc_valor_final) : null;
      lo_params.parc_valor_pagto_inicial  ? lo_params.parc_valor_pagto_inicial  = Number(lo_params.parc_valor_pagto_inicial) : null;
      lo_params.parc_valor_pagto_final    ? lo_params.parc_valor_pagto_final    = Number(lo_params.parc_valor_pagto_final) : null;
      
      // se for da conciliação não trazercobranças conciliadas
      if(!!this.dialog_lancamentos){
        lo_params.conciliado = true
      }

      la_JSON = await ContasReceberGet(lo_params);

      this.count_dados = la_JSON.count;
      this.total_aberto_parc                    = 0;
      this.total_aberto_valor                   = 0;
      this.total_vencidas_parc                  = 0,
      this.total_vencidas_valor                 = 0;
      this.total_pago_parc                      = 0;
      this.total_pago_valor                     = 0;
      this.total_parc_selecionado               = 0;
      this.total_parc_valor_selecionado         = 0;
      this.total_pago_valor_selecionado         = 0;
      
      if (la_JSON.rows && la_JSON.rows.length > 0)  {
        this.store_ContasReceber.paginas = la_JSON.paginas;
        this.cobrancas = la_JSON.rows.map(v => ({value: false, 
                                                docs: v.cobr_gedvinculo.reduce((acc, item) => {
                                                                                          const { tipo_documento } = item;
                                                                                          if (!acc[tipo_documento]) {
                                                                                            acc[tipo_documento] = 0; // Inicializa a contagem se a chave ainda não existe
                                                                                          }
                                                                                          acc[tipo_documento] += 1; // Incrementa a contagem
                                                                                          return acc;
                                                                                        }, {}),
                                                ...v,}));
        await this.rodape()
      }
      else
        this.cobrancas = [];

      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 80,
                            container: '.v-data-table__wrapper'
                          }
                        )
    },

    async rodape(){
      // Somando valores do rodapé
      this.total_aberto_parc      = 0;
      this.total_aberto_valor     = 0;
      this.total_vencidas_parc    = 0,
      this.total_vencidas_valor   = 0;
      this.total_pago_parc        = 0;
      this.total_pago_valor       = 0;
      this.total_parc_selecionado = 0;
      this.total_parc_valor_selecionado = 0;
      this.total_pago_valor_selecionado = 0;
      this.situacao_atual         = null;
      this.cobrancas.forEach(elem => {
        this.situacao_atual       = elem.empreendcobr_situacao
        if(moment().format('YYYY-MM-DD') <= moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
            elem.empreendcobr_pagto_dt == null &&
            elem.empreendcobr_situacao === 'Aberto') {
          this.total_aberto_parc    += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_aberto_valor   += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor_final : 0;
        }
        else if(moment().format('YYYY-MM-DD') > moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
                  elem.empreendcobr_pagto_dt == null &&
                  elem.empreendcobr_situacao === 'Aberto') {
          this.total_vencidas_parc  += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_vencidas_valor += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor_final : 0;
        }
        this.total_pago_parc        += elem.empreendcobr_situacao === 'Pago'   ? 1 : 0;
        this.total_pago_valor       += elem.empreendcobr_situacao === 'Pago'   ? elem.empreendcobr_pagto_valor : 0;
        elem.empreendcobr_descricao_texto = elem.empreendcobr_descricao[0].toUpperCase() +
                                            elem.empreendcobr_descricao.substring(0,4).toLowerCase().slice(1) + ' (' +
                                            elem.empreendcobr_parcela_inicial + '/' +
                                            elem.empreendcobr_parcela_final + ')';
                                            this.total_pago_parc        += elem.empreendcobr_situacao === 'Pago'   ? 1 : 0;
        
        // Totais para os Selecionados
        this.total_parc_selecionado       += (elem.value) ? 1 : 0;
        this.total_parc_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Aberto') ? elem.empreendcobr_parc_valor_final : 0;
        this.total_pago_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Pago') ? elem.empreendcobr_pagto_valor : 0;
      });
    },

    selecionaTodos(e) {
      this.$nextTick(() => {
        this.cobrancas.map((v) => {v.value = e});
        this.rodape();
      })
    },

    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    // Função para enviar dados para o filho ContasReceberModal.vue e realizar a edição
    async editar(item) {
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      if (resp) {
        this.dados_editar = {...resp};
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.acao = 'EDITAR'
        // this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        // this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        // this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dados_editar.empreendcobr_parc_valor_final = this.dados_editar.empreendcobr_parc_valor_final;
        this.dados_editar.empreendcobr_pagto_valor_juros = this.dados_editar.empreendcobr_pagto_valor_juros
        this.dialog_novo_editar = true;
         // Atualizando a grade sem chamar API
        let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == resp.cod_empreendcobranca);
        if (lo_Retorno) {
          lo_Retorno.empreendcobr_situacao               = item.empreendcobr_situacao;
          lo_Retorno.empreendcobr_pagto_valor            = item.empreendcobr_pagto_valor;
          lo_Retorno.empreendcobr_pagto_dt               = item.empreendcobr_pagto_dt;
          lo_Retorno.empreendcobr_pagto_valor_juros      = item.empreendcobr_pagto_valor_juros;
          lo_Retorno.empreendcobr_pagto_valor_multa      = item.empreendcobr_pagto_valor_multa;
          lo_Retorno.empreendcobr_parc_valor_final       = item.empreendcobr_parc_valor_final;
          lo_Retorno.empreendcobr_pagto_dt               = item.empreendcobr_pagto_dt;
        }            
      }
    },

    // Função que recebe alterações do filho ContasReceberModal.vue para atualizar a grade
    async atualiza_registro(p_JSON) {
      let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      if (lo_Retorno) {
        Object.keys(p_JSON).forEach(function (key) {
          lo_Retorno[key] = p_JSON[key];
        });  
      }
      // atualizar o rodapé
      await this.rodape()
    },

    // função para estornar
    async estornar(item){
      // console.log("🚀 ~ file: ContasReceber.vue:2384 ~ estornar ~ cod_empreendcobranca:", cod_empreendcobranca)
      // return
      //let lo_Retorno_ = await this.cobrancas.find((val) => val.cod_empreendcobranca == item);
      const lo_JSON = { cod_empreendcobranca                : item.cod_empreendcobranca,
                        empreendcobr_situacao               : "Aberto",
                        empreendcobr_pagto_valor            : null,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_pagto_valor_juros      : null,
                        empreendcobr_pagto_valor_multa      : null,
                        empreendcobr_parc_valor_final       : item.empreendcobr_parc_valor,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_parc_acres_valor       : null,
                        empreendcobr_parc_acres_obs         : null,
                        empreendcobr_parc_desc_valor        : null,
                        empreendcobr_parc_desc_obs          : null,
                      }
      this.dialog_estornar_confirmacao_2 = null;
      let ls_Res = null
      ls_Res = await this.store_ContasReceber.ContasReceberEstornar(lo_JSON);
      if (ls_Res.status == 200) {
            // Mensagem de estorno confirmado
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == item.cod_empreendcobranca);
            if (lo_Retorno) {
              // atualizar o rodapé
              await this.rodape()
              lo_Retorno.empreendcobr_situacao               = lo_JSON.empreendcobr_situacao;
              lo_Retorno.empreendcobr_pagto_valor            = lo_JSON.empreendcobr_pagto_valor;
              lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
              lo_Retorno.empreendcobr_pagto_valor_juros      = lo_JSON.empreendcobr_pagto_valor_juros;
              lo_Retorno.empreendcobr_pagto_valor_multa      = lo_JSON.empreendcobr_pagto_valor_multa;
              lo_Retorno.empreendcobr_parc_valor_final       = lo_JSON.empreendcobr_parc_valor;
              lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
              lo_Retorno.empreendcobr_parc_acres_valor       = lo_JSON.empreendcobr_parc_acres_valor;
              lo_Retorno.empreendcobr_parc_desc_valor        = lo_JSON.empreendcobr_parc_desc_valor;

              
            }             
      } else {
            // Mensagem de estorno não realizado
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    async excluir(item){
      //console.log('EXCLUINDO', item)
      let ls_Res = null
      ls_Res = await this.store_ContasReceber.ContasReceberExcluir(item);                         
      this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
        // Mensagem de exclusão confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        // Atualizando a grade sem chamar API
        let i = this.cobrancas.findIndex(data => data.cod_empreendcobranca == item);
        if (i !== -1) {
          this.cobrancas.splice(i, 1);
        }
        //this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);
        await this.rodape()
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }

    },

    async novo(){
      this.dados_editar                                     = [];
      this.dados_editar.empreend_venda                      = {};
      this.dados_editar.empreend_venda.vendas               = {};
      this.dados_editar.empreend_venda.pessoa_venda         = {};
      this.dados_editar.empreend_venda.empreendvenda_imovel = {};
      this.dialog_novo                                      = true;
      //console.log('Node',this.dados_editar)
    },

    async impressos(item){
      ////console.log("entrou impressos", item)
      const ls_Res = await this.store_ContasReceber.ContasReceberImpressos(item); 
      this.boletos = ls_Res.data.result
      ////console.log('boletos',this.boletos)
      ////console.log("ls_Res",ls_Res.data.result)
    },

    // Função para enviar dados para o filho ContasReceberModal.vue pagamento/baixa 
    async pagar(item){
      this.dados_editar = [];
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      // console.log("novo", resp)

      if (resp) {
        this.dados_editar = {...resp};
        // console.log("dados2", this.dados_editar, "item+++++++", item)
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.empreendcobr_situacao == 'Pago' ? this.dados_editar.acao2 = 'VISUALIZAR': this.dados_editar.acao2 = 'PAGAR';
        this.dados_editar.acao = 'PAGAR'
        //this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L')
        this.dados_editar.empreendcobr_situacao != 'Pago' ? this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L') : this.dados_editar.empreendcobr_pagto_dt = formatDate(item.empreendcobr_pagto_dt);
        // this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        // this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        // this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dialog_novo_editar = true;       
      }
    },

    // Função para enviar dados para o filho ContasReceberModal.vue e realizar vizualização
    async visualizar(item){
      this.dados_editar = [];
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      if (resp) {
        this.dados_editar = {...resp};
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.acao = 'VISUALIZAR';
        //this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L');
        this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dialog_novo_editar = true; 
        //console.log(this.dialog.dados_editar)      
      }
    },

    documentos(item) {
      this.dados_documentos = {...item}

      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.dados_documentos.tipo = 'CONTAS A RECEBER';
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    async cnab() {
      const lo_JSON = { "type"  : "pdf",
                        "data"  : [ { "valor":5.0,
                                      "cedente":"Kivanio Barbosa",
                                      "documento_cedente":"12345678912",
                                      "sacado":"Claudio Pozzebom",
                                      "sacado_documento":"12345678900",
                                      "agencia":"0810",
                                      "conta_corrente":"53678",
                                      "convenio":12387,
                                      "nosso_numero":"12345678",
                                      "bank":"itau"
                                    },
                                    { "valor": 10.00,
                                      "cedente": "PREFEITURA MUNICIPAL DE VILHENA",
                                      "documento_cedente": "04092706000181",
                                      "sacado": "João Paulo Barbosa",
                                      "sacado_documento": "77777777777",
                                      "agencia": "1825",
                                      "conta_corrente": "0000528",
                                      "convenio": "245274",
                                      "nosso_numero": "000000000000001",
                                      "bank":"caixa"
                                    }
                                  ]
                      }

      // const resp = await API_CNAB.post(`/boleto/multi`, JSON.stringify(lo_JSON));

      // este abaixo deu CERTO
      // const resp = await API_CNAB.get('boleto/nosso_numero?bank=itau&data=%7B"valor":0.0,"cedente":"Kivanio%20Barbosa","documento_cedente":"12345678912","sacado":"Claudio%20Pozzebom","sacado_documento":"12345678900","agencia":"0810","conta_corrente":"53678","convenio":12387,"numero_documento":"12345678"%7D');
    
      // axios.post('http://localhost:9292/api', lo_JSON)
      //   .then(response => //console.log(response))
      //   .catch(error => //console.log(error)) 
    },

    // Acoes de Boletos
    async baixarBoleto(item) {
      let ls_Res = null
      //console.log('chamou function Front')
      ls_Res = await this.store_ContasReceber.ContasReceberImpressosBaixar(item); 
      //console.log('chamou function Front', ls_Res)                        
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    async reimprimirBoleto(item) {
      console.log("🚀 ~ reimprimirBoleto ~ item:", item)
      // const lo_Item = {
      //               "banco" : "Sicoob",
      //               "boletos"	: [
      //                     {
      //                       "pagador": {
      //                         "nome": "José Bonifácio de Andradaaaaaa",
      //                         "RegistroNacional": "12345678",
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "instrucoes": ["Após o vencimento Mora dia R$ 1,59", "Após o vencimento, multa de 2%"],
      //                       "beneficiario": {
      //                         "nome": "Empresa Fictícia LTDA",
      //                         "cnpj": "03411967000154",
      //                         "dadosBancarios": {
      //                           "carteira": "1",
      //                           "agencia": "3214",
      //                           "agenciaDigito": "0",
      //                           "conta": "4995163",
      //                           "contaDigito": "0",
      //                           "nossoNumero": "0000001",
      //                         "nossoNumeroDigito": "0"
      //                         },
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "boleto": {
      //                         "numeroDocumento": "1001",
      //                         "especieDocumento": "DM",
      //                         "valor": 110.00,
      //                         "datas": {
      //                           "vencimento": "02-04-2020",
      //                           "processamento": "02-04-2019",
      //                           "documentos": "02-04-2019"
      //                         }
      //                       }
      //                     }
      //               ]
      //             }      
      ////console.log("item", item)
      
      let la_Items = [];

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (BACKEND) -----------------------
      // for (var i = 0; i < 1; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // }
      // const lo_Item =  [{Name: "COD_IMPRESSAO", Value : la_Items}]
      // let data = {
      //   nome        : "Financeiro.Boleto.Sicoob",
      //   tipo        : "pdf",
      //   parametros  : lo_Item,
      //   //mail       : { to      : ['fernando@viscom.com.br'],
      //   //               subject : 'Boleto Automático Viscom (teste)'},
      // };
      // this.loading_relatorio = true;
      // let ls_Resp = await Relatorio(data);
      // this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (FRONTEND) -----------------------
      for (var i = 0; i < 1; i++) {
        la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
        const resp = await API.get("/erp/contasreceber/contasreceber_reimprimirboletos",
                                  { params: {
                                      cod_empreendcobranca_impressao: parseInt(item.cod_empreendcobranca_impressao) + i
                                    }
                                  }
                                );
      }
      this.loading_relatorio = true;
      await AbreRelatorioExterno("Financeiro.Boleto." + item.banco_cod_compensacao,
                                  { Name: "COD_IMPRESSAO", Value: la_Items});
      this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio pdfME --------------------------
      // for (var i = 0; i < 200; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // // la_Items.push( {
      // //                   "name": "Parra Imobiliária Ltda",
      // //                   "age": "4 years",
      // //                   "sex": "Male",
      // //                   "weight": "33 pounds",
      // //                   "breed": "Mutt",
      // //                   "vencimento1": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "vencimento2": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "valor1": formatNumber(item.empreendcobr_impr_valor),
      // //                   "valor2": formatNumber(item.empreendcobr_impr_valor),
      // //                   "cod_bar": item.empreendcobr_codigobarra,
      // //                   "linha_digitavel1": item.empreendcobr_linhadigitavel,
      // //                   "linha_digitavel2": item.empreendcobr_linhadigitavel,
      // //                   "nosso_numero1": item.empreendcobr_nossonumero,
      // //                   "nosso_numero2": item.empreendcobr_nossonumero,
      // //                 }
      // //               );
      // }
      // const ls_Items = la_Items.join(',')
      // //console.log('ls_Items', ls_Items);
      // const resp = await API.get("/erp/contasreceber/contasreceber_reimprimirboletos",
      //                             { params: {
      //                                 cod_empreendcobranca_impressao: ls_Items
      //                               }
      //                             }
      //                           );
      // if (resp.data && resp.data.result) {
      //   let data = {
      //     tipo: "pdfME",
      //     inputs: resp.data.result,
      //     destino: "EMAIL NAO",
      //   };
      //   this.loading_relatorio = true;
      //   await Relatorio(data);
      //   this.loading_relatorio = false;
      //   //console.log("resp.data.result", resp.data.result);
      // }

      //////////////////////////////////////////////////////////////////////////////////////////
    },

    async excluirBoleto(item) {
      let ls_Res = null
      //console.log('chamou function Front')
      ls_Res = await this.store_ContasReceber.ContasReceberImpressosExcluir(item); 
      //console.log('chamou function Front', ls_Res)                        
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    // Retorna os codigos das cobrancas selecionadas
    selecionados() {
      //console.log('selecionados')
			return this.cobrancas.filter((d) => d.value == true).map((v) => {return v.cod_empreendcobranca})
		},

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    abriuConsulta(val) {
      //console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_ContasReceber.filtro};
        });
      }
    },

    handlePageChange(value) {
      this.currentPage = value;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 60,
                            container: '.v-data-table__wrapper'
                          }
                        )
      
    },

  }
}  

</script>

<style scoped>

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .date-picker {
    max-width: 200px!important;
  }

  .table tr {
    width: 100%;
  }

  .table-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* .table-row:hover {
    background: #f6f6f6 !important;
  } */

  .v-btn {
    display: inline-block;
  }

  /*-----------------------------------*/
  /*------ RESPONSIVE GENERAL ------*/
  /*-----------------------------------*/
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  .v-toolbar__content {
    padding-left: 0px !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-principal {
    max-width: 100%;
    margin: auto !important;
  }

  .container-fundo {
    height: calc(100vh -30px) !important;
  }

  .container-rounded {
    background: #fff !important;
    border-radius: 12px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
    height: 22px !important;
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos-fundo {
    background-color: rgb(235, 235, 239) !important;
  }

  .data-table-impressos > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > tbody > tr > th  {
    background-color: rgb(2, 245, 245) !important;
    height: 20px !important;
  }

  #virtual-scroll-table-tablet {
    display: none;
  }

  /* .table {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 300px !important;
  }

  .table-td {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 330px !important;
    height: 30px !important;
  } */

  .card-container {
    padding: 16px!important;
  }

  .false-filter {
    background-color:#6C87A2;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
  }

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

  .v-select__slot {
    display: none !important;
  }

  .checkbox {
    margin-bottom: 3px;
    margin-top: 1px;
  }

  /* ::v-deep .v-data-footer {
    align-content: center;
    color: var(--COR_PRINCIPAL) !important;  
    font-size:14px !important;
    height: 35px;
    padding-top: 10px !important;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }
 */

 .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }


  .width-nome {
    display: block;
    min-width: 250px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .width-parcela {
    min-width: 120px;
    white-space: nowrap;
  }

  .width-nome-customer {
    white-space: nowrap;
  }

  .width-empreendimento {
    display: block;
    min-width: 200px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .linhas {
    height:55px;
    background-color:transparent;
  }

  .linhas2 {
    height:55px;
    background-color:transparent;
  }

  /* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
  .status_legend {
    font-size: 12px !important;
    font-weight: 400!important;
    padding: 2px 2px 2px 12px ;
    border-radius: 13px !important;
  }

  .status_ativo {
    font-size: 14px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_pendente {
    font-size: 14px !important;
    background: goldenrod;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_cancelado {
    font-size: 14px !important;
    background: #ff5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_legend:hover {
    opacity: 0.5;
  }

  .status_legend_a_vencer {
    background: rgb(242, 192, 98);
  }

  .status_legend_vencidas {
    background: #FF5252;
  }

  .status_legend_pagos {
    background: #469c47;
  }

  .status_legend_selecionados {
    background: #6C87A2;
  }

  /*
  .status_aberto_dia_borda {
    border: 2px solid rgb(1, 168, 1) 
  }

  .status_aberto_atraso_borda {
    /* border: 2px solid rgb(1, 168, 1) 
  }
  */
  .status_a_vencer_atraso_color {
      color: red;
  }

  .status_baixado {
    background: #EF5350;
  }

  .status_pago-atraso {
    background: #FFA726;
  }

  .status_nao-encontrado {
    background: #7E57C2;
  }

  .status_msg-banco {
    background: #BDBDBD;
  }

  .status_invalido {
    background: #F44336;
  }

  .v-menu__content {
    z-index: 10!important;
  }
  /* table.v-table tbody td, table.v-table tbody th {
    height: 17px!important;
  } */

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
  }
  
</style>
